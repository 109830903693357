import type { MouseEvent } from 'react';
import React, { useContext, useState } from 'react';
import { css } from '@compiled/react';
import { useIntl, defineMessages } from 'react-intl-next';

import EditIcon from '@atlaskit/icon/core/edit';
import { token } from '@atlaskit/tokens';
import { Box, Text, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import { usePageLayoutResize } from '@atlaskit/page-layout';
import { SpotlightManager as AkSpotlightManager } from '@atlaskit/onboarding';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';

import { SPACE_INDEX, SPACE_OVERVIEW } from '@confluence/named-routes';
import { SPAViewContext } from '@confluence/spa-view-context';
import { EditSpaceLogo } from '@confluence/edit-space-logo';
import {
	ExternalCollaboratorsSpaceInformationMessage,
	ExternalCollaboratorsSpaceInformationMessageType,
} from '@confluence/space-guest-list/entry-points/ExternalCollaboratorsSpaceInformationMessage';
import { ExternalCollaboratorInSpaceNotify } from '@confluence/space-guest-list';
import { GuestOnboardingModalLoadable } from '@confluence/external-collab-ui';
import { LoadableLazy } from '@confluence/loadable';
import { useRouteName } from '@confluence/route-manager';
import {
	EDIT_SPACE_LOGO_DETAILS_LOAD_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';

import { SpaceMoreActionsMenu } from './SpaceMoreActionsMenu';

type SpaceHeaderProps = {
	spaceId: string;
	spaceName: string;
	spaceKey: string;
	homepageId: string | undefined;
	iconPath: string | undefined;
	isStarred: boolean;
	isWatched: boolean;
	isSpaceAdmin: boolean;
	isPersonalSpace: boolean;
	isSpaceArchived: boolean;
};

type ExternalCollaboratorProps = {
	isExternalCollaborator: boolean;
	containsExternalCollaborators: boolean;
};

export const SpaceHeader = ({
	isExternalCollaborator,
	containsExternalCollaborators,
	...props
}: SpaceHeaderProps & ExternalCollaboratorProps) => {
	// Changeboarding for how to find a space's list of external collaborators by invoking a link from the space header:
	// Step 1: show an onboarding modal to display a space's list of external collaborators
	// Step 2: dismissing the modal stops the modal from being shown again in the future (shows only once per user per Confluence site)
	// Step 3: show a nonintrusive nudge "pulse" around the space header (when conditions are right)
	// Step 4: when user hovers over the pulse, show the full spotlight complete with overlay and message
	// Step 5: clicking on the spotlight action dismisses the entire changeboarding
	// This will only work if messages are shown in the same session. We'll need to find another implmentation if user has seen message #1 (modal) already, but not message #2 (pulse)
	const [showExCoOnboardingNudge, setShowExCoOnboardingNudge] = useState(false);

	const [onboardingStep, setOnboardingStep] = useState(0);

	const { isLeftSidebarCollapsed } = usePageLayoutResize();
	const { isNewUser } = useContext(SPAViewContext);
	const showExCoMessaging = !isExternalCollaborator && containsExternalCollaborators;

	// Suppress exco onboarding if the user is new, since they will be bombarded with other unrelated onboarding
	// messages which we don't want to overlap with this one. (See: CONFDEV-75463) We are seeking a more permanent
	// solution to onboarding overlapping with EP changeboarding messages, but for now adding the !isNewUser check
	// will prevent that behavior.
	const showExCoModalOnboarding = !isNewUser && showExCoMessaging;

	const showExCoSpotlightOnboarding =
		!isNewUser && showExCoMessaging && showExCoOnboardingNudge && !isLeftSidebarCollapsed;

	const onGuestListOnboardingModalShown = () => {
		setShowExCoOnboardingNudge(true);
		setOnboardingStep(1);
	};

	const header = <SpaceHeaderInner {...props} showExCoMessaging={showExCoMessaging} />;

	return (
		<AkSpotlightManager>
			{showExCoSpotlightOnboarding && onboardingStep === 1 ? (
				<SpaceHeaderExCoOnboarding
					header={header}
					onClose={() => setShowExCoOnboardingNudge(false)}
				/>
			) : (
				header
			)}
			{showExCoModalOnboarding && onboardingStep === 0 && (
				<ExternalCollaboratorInSpaceNotify
					onModalClose={onGuestListOnboardingModalShown}
					spaceKey={props.spaceKey}
				/>
			)}
			<GuestOnboardingModalLoadable />
		</AkSpotlightManager>
	);
};

const SpaceHeaderInner = ({
	spaceId,
	spaceName,
	spaceKey,
	homepageId,
	iconPath,
	isStarred,
	isWatched,
	isSpaceAdmin,
	isPersonalSpace,
	showExCoMessaging,
	isSpaceArchived,
}: SpaceHeaderProps & { showExCoMessaging: boolean }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isSelected = useRouteName(isOnSpaceRouteArgs);

	const spaceOverviewHref = SPACE_OVERVIEW.toUrl(
		{ spaceKey },
		homepageId
			? {
					query: { [SPACE_OVERVIEW.HOMEPAGE_ID_QUERY_KEY]: homepageId },
				}
			: undefined,
	);

	const description = showExCoMessaging ? (
		<Text weight="medium">
			<ExternalCollaboratorsSpaceInformationMessage
				analyticsSource="spaceHeader"
				messageType={ExternalCollaboratorsSpaceInformationMessageType.IN_SPACE}
				shouldShowMessageIcon={false}
				shouldShowAdditionalMessageContext={false}
				spaceKey={spaceKey}
				isSubtle
			/>
		</Text>
	) : undefined;

	const onClick = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'spaceHeader',
				source: 'sideNavigation',
				attributes: {
					navVersion: 4,
				},
			},
		}).fire();
	};

	return (
		<>
			<Box xcss={stickyWrapper}>
				<MenuLinkItem
					href={spaceOverviewHref}
					isSelected={isSelected}
					onClick={onClick}
					elemBefore={
						<EditableSpaceIcon
							spaceKey={spaceKey}
							iconPath={iconPath}
							isSpaceAdmin={isSpaceAdmin}
							isPersonalSpace={isPersonalSpace}
						/>
					}
					actionsOnHover={
						<SpaceMoreActionsMenu
							spaceId={spaceId}
							spaceKey={spaceKey}
							isStarred={isStarred}
							isWatched={isWatched}
							isSpaceAdmin={isSpaceAdmin}
							isSpaceArchived={isSpaceArchived}
						/>
					}
					// @ts-expect-error Platform component current only accepts a string; we're passing an element anyway.
					description={description}
				>
					{spaceName}
				</MenuLinkItem>
			</Box>
			{/**
			 * We need a conditional border which appears only when the header is 'sticking' (i.e. the user has scrolled the navigation).
			 *
			 * To acheive this, we use two borders:
			 * 1. A sticky border which is always present
			 * 2. An absolute positioned border which covers the sticky border when the header is not sticking.
			 *    This border is the same color as the background of the side nav, so it appears as if the sticky border has disappeared.
			 *
			 * When the user scrolls, the absolute border will scroll out of the way, revealing the sticky border which remains in place.
			 **/}
			<div css={stickyBorder} />
			<div css={stickyBorderCover} />
		</>
	);
};

type EditableSpaceIconProps = Pick<
	SpaceHeaderProps,
	'spaceKey' | 'iconPath' | 'isSpaceAdmin' | 'isPersonalSpace'
>;

const EditableSpaceIcon = ({
	spaceKey,
	iconPath,
	isSpaceAdmin,
	isPersonalSpace,
}: EditableSpaceIconProps) => {
	const intl = useIntl();
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { leftSidebarState, expandLeftSidebar } = usePageLayoutResize();

	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [shouldRenderDialog, setShouldRenderDialog] = useState(false);

	const image = iconPath ? (
		<img src={iconPath} alt="" css={icon} data-vc="space-header-icon-image" />
	) : null;

	const canEdit = isSpaceAdmin && !isPersonalSpace;
	if (!canEdit) {
		return image;
	}

	const editLabel = intl.formatMessage(i18n.editSpaceDetails);

	const onClick = (e: MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();

		experienceTracker.start({
			name: EDIT_SPACE_LOGO_DETAILS_LOAD_EXPERIENCE,
			timeout: 10000,
		});

		setShouldRenderDialog(true);
		setIsDialogOpen(true);

		if (leftSidebarState.isFlyoutOpen) {
			expandLeftSidebar();
		}

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'editSpaceDetails',
				source: 'sideNavSpaceHeader',
				attributes: {
					navVersion: 4,
				},
			},
		}).fire();
	};

	const onFocus = () => {
		setShouldRenderDialog(true);
	};

	const onMouseEnter = () => {
		setShouldRenderDialog(true);
	};

	const onDialogOpen = () => {
		setIsDialogOpen(true);
	};

	const onDialogClose = () => {
		setIsDialogOpen(false);
	};

	return (
		<>
			<Tooltip content={editLabel}>
				<button
					css={iconButton}
					aria-label={editLabel}
					onClick={onClick}
					onFocus={onFocus}
					onMouseEnter={onMouseEnter}
				>
					{image}
					<Box xcss={editIconWrapper}>
						<EditIcon label="" color={token('color.icon.inverse')} />
					</Box>
				</button>
			</Tooltip>
			{shouldRenderDialog && (
				<EditSpaceLogo
					spaceKey={spaceKey}
					isOpen={isDialogOpen}
					onOpen={onDialogOpen}
					onClose={onDialogClose}
				/>
			)}
		</>
	);
};

const isOnSpaceRouteArgs = {
	selector: (routeName: string | undefined) =>
		routeName === SPACE_INDEX.name || routeName === SPACE_OVERVIEW.name,
};

const stickyWrapper = xcss({
	position: 'sticky',
	top: 'space.0',
	zIndex: 'navigation', // Needs to be at least z-index 2 to appear on top of Content and Shortcuts
	backgroundColor: 'elevation.surface',
});

const icon = css({
	width: '24px',
	height: '24px',
	borderRadius: '6px',
});

const iconButton = css({
	border: 'none',
	background: 'none',
	padding: 0,
	position: 'relative',
	width: '24px',
	height: '24px',
	cursor: 'pointer',
	marginTop: token('space.050'),
});

const editIconWrapper = xcss({
	position: 'absolute',
	top: 'space.050',
	left: 'space.050',
	opacity: 0,
	transition: 'opacity 0.5s ease',
	':hover': {
		opacity: 1,
	},
});

const stickyBorder = css({
	position: 'sticky',
	width: '100%',
	borderBottom: `1px solid ${token('color.border')}`,
	top: '32px', // This is the same as the height of MenuLinkItem
	zIndex: 1,
});

const stickyBorderCover = css({
	position: 'absolute',
	width: '100%',
	borderBottom: `1px solid ${token('elevation.surface')}`, // This is the same as the background color of side nav
	top: '32px', // This is the same as the height of MenuLinkItem
	zIndex: 2, // Appears above the sticky border
});

const SpaceHeaderExCoOnboarding = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SpaceHeaderExCoOnboarding" */ './SpaceHeaderExCoOnboarding'
			)
		).SpaceHeaderExCoOnboarding,
});

const i18n = defineMessages({
	editSpaceDetails: {
		id: 'side-navigation.space-navigation.header.edit-space-details',
		defaultMessage: 'Edit space details',
		description: 'Text for a tooltip and label indicating where to click to edit space details',
	},
});

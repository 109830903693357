import { useMutation } from '@apollo/react-hooks';
import type { FC } from 'react';
import React, { Fragment, useCallback, memo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { LinkItem } from '@atlaskit/side-navigation';
import { N500, N20, B50 } from '@atlaskit/theme/colors';
import Tooltip from '@atlaskit/tooltip';

import { ConfluenceEdition } from '@confluence/change-edition';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { fg } from '@confluence/feature-gating';
import {
	SPACE_CALENDARS,
	SPACE_QUESTIONS,
	SPACE_QUESTIONS_NATIVE,
	SPACE_BLOGS,
	VIEW_BLOG,
	VIEW_BLOG_DATE_LEGACY,
	SPACE_PAGES,
	DATABASE_CUSTOM_OVERVIEW,
	SPACE_CALENDARS_SUBCALENDAR,
	SPACE_SETTINGS_AUTOMATION,
} from '@confluence/named-routes';
import { useRouteDataRef, useRouteName } from '@confluence/route-manager';
import { usePageSpaceKey } from '@confluence/page-context';
import type { Route } from '@confluence/route';
import { SpaceSettingsSidebarLinks } from '@confluence/space-settings';
import { useSessionData } from '@confluence/session-data';
import { LoadableAfterPaint } from '@confluence/loadable';
import { useIsBlogTreeUnderContentTreeFFEnabled } from '@confluence/blog-tree/entry-points/useIsBlogTreeUnderContentTreeFFEnabled';

import type { SpaceNavigationQuery_spaceSidebarLinks_main } from './__types__/SpaceNavigationQuery';
import { SpaceNavigationQuery } from './SpaceNavigationQuery.graphql';
import { AddSpaceLink } from './AddSpaceLink.experimentalgraphql';
import { BlogNavigation } from './BlogNavigation';
import { RemoveSpaceLink } from './RemoveSpaceLink.experimentalgraphql';
import { useSidebarConfigurationListener } from './useSidebarConfigurationListener';
import {
	ANALYTICS_KEY,
	CALENDARS_KEY,
	QUESTIONS_KEY,
	SPACE_SETTINGS_KEY,
	DATABASES_KEY,
	AUTOMATION_KEY,
	BULK_TOOLS_KEY,
	QUESTIONS_NATIVE_KEY,
} from './webItemCompleteKeys';
import { DatabasesNavigation } from './DatabasesNavigation';
import { AnalyticsNavigation } from './AnalyticsNavigation';

const GridIcon = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-GridIcon" */ './img/GridIcon')).GridIcon,
});
const AutomationNavigation = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-AutomationNavigation" */ './AutomationNavigation'))
			.AutomationNavigation,
});
const QuestionsNavigation = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-QuestionsNavigation" */ './QuestionsNavigation'))
			.QuestionsNavigation,
});
const TeamCalendarNavigation = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-TeamCalendarNavigation" */ './TeamCalendarNavigation'
			)
		).TeamCalendarNavigation,
});
const BulkToolsNavigation = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-BulkToolsNavigation" */ './BulkToolsNavigation'))
			.BulkToolsNavigation,
});

const i18nTooltips = defineMessages({
	allContentTooltip: {
		id: 'side-navigation.space-navigation.all-content.tooltip',
		defaultMessage: 'View all content in this space',
		description: 'Tooltip message on hover of All Content space link',
	},
});

export const cssFn = ({ isSelected = false, isDatabaseIcon = false }) => ({
	padding: `0px ${token('space.050', '4px')} 0px ${token('space.100', '8px')}`,
	height: '36px',
	'min-height': '36px',
	'border-radius': '3px',
	'font-size': '14px',
	span: {
		columnGap: token('space.100', '8px'),
	},
	backgroundColor: isSelected ? token('color.background.selected', B50) : 'transparent',
	'&:hover': {
		backgroundColor: isSelected
			? token('color.background.selected.hovered', '#cce0ff')
			: token('color.background.neutral.subtle.hovered', N20),
	},
	'&, &:visited, &:active, &:focus': {
		color: isSelected ? token('color.text.selected', '#0c66e4') : token('color.text.subtle', N500),
	},
	textDecoration: 'none !important',
	'[data-item-elem-before=true]': {
		'margin-right': '0px',
		height: '24px',
		width: '24px',
		'img, span, svg': {
			height: isDatabaseIcon ? '18px' : '20px',
			width: isDatabaseIcon ? '18px' : '20px',
		},
	},
});

type LegacySpaceLinksProps = {
	isSpaceAdmin: boolean;
	links: SpaceNavigationQuery_spaceSidebarLinks_main[];
	isSpaceSettingsScreen?: boolean;
	blogsHidden?: boolean;
};

// If pathname is undefined or empty string, then the pathname || "" check will return "/" and match
// the route against "/", otherwise it will match the route against the pathname passed in
export const isRoute = (pathname: string | undefined, route: Route) =>
	Boolean(route.match(pathname || ''));

const isOnDatabasesRouteArgs = {
	selector: (routeName: string | undefined) => routeName === DATABASE_CUSTOM_OVERVIEW.name,
};
const isOnSpacePagesRouteArgs = {
	selector: (routeName: string | undefined) => routeName === SPACE_PAGES.name,
};
const isOnBlogRouteArgs = {
	selector: (routeName: string | undefined) =>
		routeName === VIEW_BLOG.name ||
		routeName === SPACE_BLOGS.name ||
		routeName === VIEW_BLOG_DATE_LEGACY.name,
};
const isOnAutomationRouteArgs = {
	selector: (routeName: string | undefined) => routeName === SPACE_SETTINGS_AUTOMATION.name,
};
const isOnSpaceCalendarsRouteArgs = {
	selector: (routeName: string | undefined) =>
		routeName === SPACE_CALENDARS.name || routeName === SPACE_CALENDARS_SUBCALENDAR.name,
};
const isOnSpaceQuestionsRouteArgs = {
	selector: (routeName: string | undefined) => routeName === SPACE_QUESTIONS.name,
};
const isOnSpaceQuestionsNativeRouteArgs = {
	selector: (routeName: string | undefined) => routeName === SPACE_QUESTIONS_NATIVE.name,
};

export const LegacySpaceLinks: FC<LegacySpaceLinksProps> = memo(
	({ isSpaceAdmin, links, isSpaceSettingsScreen = false, blogsHidden }) => {
		const [spaceKey] = usePageSpaceKey();
		const routeDataRef = useRouteDataRef();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const { isLicensed, edition } = useSessionData();
		const isBlogTreeUnderContentTreeFFEnabled = useIsBlogTreeUnderContentTreeFFEnabled();
		const isQuestionsNativeEnabled = fg('cc_editions_cq_native');

		const isPremiumEdition = edition === ConfluenceEdition.PREMIUM;

		const databasesRoute = DATABASE_CUSTOM_OVERVIEW;

		const [addSpaceLink] = useMutation(AddSpaceLink, {
			refetchQueries: [
				{
					query: SpaceNavigationQuery,
					variables: { spaceKey, isLicensed },
				},
			],
		});
		const [removeSpaceLink] = useMutation(RemoveSpaceLink, {
			refetchQueries: [
				{
					query: SpaceNavigationQuery,
					variables: { spaceKey, isLicensed },
				},
			],
		});

		useSidebarConfigurationListener(addSpaceLink, removeSpaceLink);

		const sendItemClickedAnalytics = useCallback(
			(itemId: any, navdexPointType: any, additionalAttributes = {}) =>
				() => {
					createAnalyticsEvent({
						type: 'sendUIEvent',
						data: {
							action: 'clicked',
							actionSubject: 'navigationItem',
							actionSubjectId: 'spaceNavigationItem',
							source: 'containerNavigation',
							attributes: {
								navigationLayer: 'container',
								itemId,
								selectedItemPageContext: routeDataRef.current?.routeName,
								navVersion: '3',
								navdexPointType,
								...additionalAttributes,
							},
						},
					}).fire();
				},
			[createAnalyticsEvent, routeDataRef],
		);
		const isOnSpacePagesRoute = useRouteName(isOnSpacePagesRouteArgs);
		const isOnBlogRoute = useRouteName(isOnBlogRouteArgs);
		const isOnAutomationRoute = useRouteName(isOnAutomationRouteArgs);
		const isOnDatabasesRoute = useRouteName(isOnDatabasesRouteArgs);
		const isOnSpaceCalendarsRoute = useRouteName(isOnSpaceCalendarsRouteArgs);
		const isOnSpaceQuestionsRoute = useRouteName(isOnSpaceQuestionsRouteArgs);
		const isOnSpaceQuestionsNativeRoute = useRouteName(isOnSpaceQuestionsNativeRouteArgs);

		const getBuiltInItemsMapping = useCallback(
			() => ({
				[ANALYTICS_KEY]: (
					<AnalyticsNavigation onClick={sendItemClickedAnalytics('analytics', undefined)} />
				),
				[QUESTIONS_KEY]: (
					<QuestionsNavigation
						key={QUESTIONS_KEY}
						isSelected={isOnSpaceQuestionsRoute}
						onClick={sendItemClickedAnalytics('questions', undefined)}
						href={SPACE_QUESTIONS.toUrl({ spaceKey })}
					/>
				),
				...(isPremiumEdition &&
					isQuestionsNativeEnabled && {
						[QUESTIONS_NATIVE_KEY]: (
							<QuestionsNavigation
								key={QUESTIONS_NATIVE_KEY}
								isSelected={isOnSpaceQuestionsNativeRoute}
								onClick={sendItemClickedAnalytics('questions', undefined)}
								href={SPACE_QUESTIONS_NATIVE.toUrl({ spaceKey })}
							/>
						),
					}),
				...(isSpaceAdmin && {
					[AUTOMATION_KEY]: (
						<AutomationNavigation
							key={AUTOMATION_KEY}
							isSelected={isOnAutomationRoute}
							onClick={sendItemClickedAnalytics('automation', undefined, {
								edition,
							})}
							isSpaceAdmin={isSpaceAdmin}
						/>
					),
				}),
				[CALENDARS_KEY]: (
					<TeamCalendarNavigation
						key={CALENDARS_KEY}
						isSelected={isOnSpaceCalendarsRoute}
						onClick={sendItemClickedAnalytics('calendars', undefined, {
							isPremiumEdition,
						})}
					/>
				),
				[DATABASES_KEY]: (
					<DatabasesNavigation
						key={DATABASES_KEY}
						isSelected={isOnDatabasesRoute}
						onClick={sendItemClickedAnalytics('databases', undefined)}
						databasesRoute={databasesRoute}
					/>
				),
				...(isPremiumEdition &&
					isSpaceAdmin && {
						[BULK_TOOLS_KEY]: (
							<BulkToolsNavigation
								key={BULK_TOOLS_KEY}
								testId="bulk-tools-link"
								onClick={sendItemClickedAnalytics('bulkTools', undefined)}
							/>
						),
					}),
			}),
			[
				sendItemClickedAnalytics,
				isOnSpaceQuestionsRoute,
				isPremiumEdition,
				isQuestionsNativeEnabled,
				isOnSpaceQuestionsNativeRoute,
				spaceKey,
				isSpaceAdmin,
				isOnAutomationRoute,
				edition,
				isOnSpaceCalendarsRoute,
				isOnDatabasesRoute,
				databasesRoute,
			],
		);

		const renderLegacySpaceLinks = useCallback(() => {
			const builtInItems = getBuiltInItemsMapping();

			return (
				<Fragment>
					<Tooltip
						position="top"
						content={<FormattedMessage {...i18nTooltips.allContentTooltip} />}
					>
						{(tooltipProps) => (
							<li data-vc="space-link">
								<LinkItem
									// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
									cssFn={cssFn}
									key="all-content"
									iconBefore={<GridIcon isSelected={isOnSpacePagesRoute} />}
									isSelected={isOnSpacePagesRoute}
									href={SPACE_PAGES.toUrl({ spaceKey })}
									{...tooltipProps}
									onClick={sendItemClickedAnalytics('allContent', undefined)}
								>
									<FormattedMessage
										id="side-navigation.container.all-content"
										defaultMessage="All content"
										description="Space Pages Button"
									/>
								</LinkItem>
							</li>
						)}
					</Tooltip>

					{!isBlogTreeUnderContentTreeFFEnabled && !blogsHidden && (
						<BlogNavigation
							key="blogNavigation"
							isSelected={isOnBlogRoute}
							onClick={sendItemClickedAnalytics('blog', undefined)}
						/>
					)}
					{links
						//Depending on the type of space, space settings may be hidden.
						//Make sure space settings is always shown
						.filter((link): link is SpaceNavigationQuery_spaceSidebarLinks_main =>
							Boolean(link && (!link.hidden || link.webItemCompleteKey === SPACE_SETTINGS_KEY)),
						)
						.sort((a, b) => Number(a.position) - Number(b.position))
						.map(
							({ webItemCompleteKey }) =>
								(webItemCompleteKey && builtInItems[webItemCompleteKey]) ||
								/* Hide everything else */ null,
						)}
					<ErrorBoundary attribution={Attribution.ADMIN_EXPERIENCE}>
						<SpaceSettingsSidebarLinks
							isSideNavRefreshAutomationEnabled={links.some(
								({ webItemCompleteKey, hidden }) =>
									webItemCompleteKey === AUTOMATION_KEY && hidden === false,
							)}
							spaceKey={spaceKey}
							isSpaceSettingsScreen={isSpaceSettingsScreen}
							cssFn={cssFn}
						/>
					</ErrorBoundary>
				</Fragment>
			);
		}, [
			getBuiltInItemsMapping,
			links,
			isBlogTreeUnderContentTreeFFEnabled,
			blogsHidden,
			isOnBlogRoute,
			sendItemClickedAnalytics,
			spaceKey,
			isSpaceSettingsScreen,
			isOnSpacePagesRoute,
		]);

		return renderLegacySpaceLinks();
	},
);
